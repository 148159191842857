<template>
  <WistiaEmbed :id="video_id" :popover="popover" :width="width" />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      popover() {
        return this.getValue("popover")
      },
      video_id() {
        return this.getValue("video_id")
      },
      width() {
        return this.getValue("width")
      },
    },
  }
</script>
